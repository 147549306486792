<template>
  <div v-if="formAction" style="padding: 20px">
    <div class="titles">{{ __("Node Type") }}</div>
    <div class="details">{{ contentFormInfo.node_type }}</div>
    <div class="titles">{{ __("Use In Voice Task") }}</div>
    <div class="details">{{ contentFormInfo.use_in_voice }}</div>
    <div class="titles">{{ __("Use In Messaging Task") }}</div>
    <div class="details">{{ contentFormInfo.use_in_sms }}</div>
    <div class="titles">{{ __("Use In Workflow") }}</div>
    <div class="details">{{ contentFormInfo.use_in_workflow }}</div>
    <div class="titles">{{ __("Use In Chatbot") }}</div>
    <div class="details">{{ contentFormInfo.use_in_chatbot }}</div>
    <div class="titles">{{ __("Category") }}</div>
    <div class="details">{{ contentFormInfo.category }}</div>
    <el-dialog
      :visible.sync="openModal"
      fullscreen
      :show-close="false"
      custom-class="editContentItemModal"
      destroy-on-close
    >
      <el-scrollbar :native="false">
        <div style="max-height: 100vh;">
          <el-form
            ref="contentForm"
            :rules="rules"
            :model="contentForm"
            label-position="top"
            size="large"
          >
            <el-row type="flex" style="padding-top: 34px">
              <el-col :span="12" :offset="6">
                <page-header :title="__('Node Type')" :contentId="id" />
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item :label="__('Node Type')" prop="node_type">
                  <el-input v-model="contentForm.node_type"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item :label="__('Category')" prop="category" required>
                  <el-select
                    v-model="contentForm.category"
                    style="width: 100%"
                    default-first-option
                  >
                    <el-option
                      v-for="category in this.categories"
                      :key="category.value"
                      :label="category.label"
                      :value="category.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item :label="__('Use In Voice')" prop="use_in_voice">
                  <el-checkbox v-model="contentForm.use_in_voice"></el-checkbox>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item :label="__('Use In SMS')" prop="use_in_sms">
                  <el-checkbox v-model="contentForm.use_in_sms"></el-checkbox>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item
                  :label="__('Use In Workflow')"
                  prop="use_in_workflow"
                >
                  <el-checkbox
                    v-model="contentForm.use_in_workflow"
                  ></el-checkbox>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item
                  :label="__('Use In Chatbot')"
                  prop="use_in_chatbot"
                >
                  <el-checkbox
                    v-model="contentForm.use_in_chatbot"
                  ></el-checkbox>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <div
            slot="footer"
            style="display: flex;margin-left: 25%;margin-bottom: 20px"
          >
            <save-button
              type="primary"
              @click="createOrEditNodeType"
              class="submitBtn"
              v-loading="isLoading"
              :primaryKey="id"
              variant="CreateUpdate"
            />
            <el-button @click="handleCancelBtn" class="cancelBtn">{{
              __("Cancel")
            }}</el-button>
          </div>
        </div>
      </el-scrollbar>
    </el-dialog>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import SaveButton from "@/components/SaveButton";
import { mapActions, mapState } from "vuex";
import { EventBus } from "@/EventBus";
import BaseContentInfoPanel from "@/views/build/content/mixins/BaseContentInfoPanel";
import BaseContent from "@/views/build/content/mixins/BaseContent";

export default {
  mixins: [BaseContent, BaseContentInfoPanel],
  components: {
    PageHeader,
    SaveButton
  },
  data() {
    return {
      nodeType: {},
      isSubmitting: false,
      nodeTypeFormKey: 0,
      rules: {
        node_type: [
          { required: true, trigger: "blur", message: __("Type is required") }
        ],
        category: [
          {
            required: true,
            trigger: "blur",
            message: __("Please select a category")
          }
        ]
      }
    };
  },
  computed: {
    ...mapState("nodeTypes", {
      isLoading: state => state.isLoading,
      categories: state => state.categories
    })
  },
  beforeRouteLeave(to, from, next) {
    this.nodeType = {};
    next();
  },

  methods: {
    ...mapActions("nodeTypes", {
      createNodeType: "createNodeType",
      updateNodeType: "updateNodeType",
      deleteContentMethod: "deleteNodeType"
    }),
    handleCancelBtn() {
      this.resetForm("contentForm");
      this.handleCancel();
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    createOrEditNodeType() {
      this.$refs.contentForm.validate(valid => {
        if (valid) {
          const process =
            this.id === -1 ? this.createNodeType : this.updateNodeType;
          process(this.contentForm)
            .then(data => {
              this.id === -1
                ? this.$message({
                    name: "success",
                    message: __("Node Type added successfully")
                  })
                : this.$message({
                    type: "success",
                    message: __("Node Type updated successfully")
                  });
              EventBus.$emit("list-changed", data.data);
              this.handleCancel();
            })
            .catch(err => {
              console.log(err);
              this.$message({
                type: "error",
                message: err.message
              });
            });
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/content-edit-info.scss";

::v-deep .el-select {
  .el-input.is-focus .el-input__inner {
    border-color: $content-theme-color;
  }

  .el-input__inner:hover {
    border-color: $content-theme-color;
  }

  .el-select-dropdown__item.selected {
    color: $content-theme-color;
  }

  .el-input__inner:focus {
    border-color: $content-theme-color;
  }

  .el-select__tags {
    ::v-deep .el-tag {
      background-color: $content-theme-color !important;
      border-color: $content-theme-color !important;
      .el-tag__close {
        background-color: $content-theme-color !important;
      }
    }
  }
}

::v-deep .el-checkbox {
  :hover {
    border-color: $content-theme-color;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner {
    border-color: $content-theme-color;
    background: $content-theme-color;
  }
  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: $content-theme-color;
  }
}
</style>
