<script>
import { mapState } from "vuex";
import List from "./pages/listNodeTypes";
import CreateOrEdit from "./pages/createOrEditNodeType";
import BaseIndex from "@/views/build/content/mixins/BaseIndex";

export default {
  name: "NodeTypesIndex",
  mixins: [BaseIndex],
  components: {
    List,
    CreateOrEdit
  },

  data() {
    return {
      selectedContent: this.initContent()
    };
  },

  computed: {
    ...mapState("nodeTypes", {
      contents: state => state.nodeTypes
    })
  },

  methods: {
    initContent() {
      return {
        node_type: "",
        use_in_voice: false,
        use_in_sms: false,
        use_in_workflow: false,
        use_in_chatbot: false,
        category: ""
      };
    }
  },
  mounted() {
    this.contentType = "Node Type";
    this.primaryKey = "node_type";
    this.scope = "sys";
  }
};
</script>

<style lang="scss" scoped></style>
