<template>
  <div style="width: 100%;" @click="handleClear">
    <page-header
      class="absolute"
      style="padding: 20px 30px; width: calc(100% - 335px)"
      :title="__('Node Types')"
    />
    <div style="padding: 0 36px; margin-top: 136px;">
      <el-form class="form-container">
        <PaginationToolbar
          :sort-by-options="sortByOptions"
          :content-api="contentAPI"
          :show-slot="true"
          :default-sort-by="__('Name A-Z')"
          @success="$emit('replaceContent', $event)"
        >
        </PaginationToolbar>
      </el-form>
      <el-scrollbar :native="true">
        <div class="contentListContainer">
          <el-table
            ref="nodeTypeTable"
            v-loading="isLoading"
            :data="nodeTypes"
            @row-click="handleSingleClick"
            highlight-current-row
            class="list-table"
          >
            <el-table-column :label="__('Node Type')">
              <!--<template slot-scope="scope">
                  <span>{{ scope.row.node_type }}</span>
                </template>-->
              <template slot-scope="scope">
                <el-row
                  type="flex"
                  justify="flex-start"
                  style="align-items: center"
                >
                  <img
                    class="rounded-menu-icon"
                    :src="`/icons/${scope.row.node_type}.svg`"
                    onload="SVGInject(this)"
                  />
                  <span style="margin-left: 20px;">{{
                    scope.row.node_type
                  }}</span>
                </el-row>
              </template>
            </el-table-column>
            <el-table-column :label="__('Use In')">
              <template slot-scope="scope">
                <span v-if="scope.row.use_in_voice">{{
                  __("Voice Task")
                }}</span>
                <span v-if="scope.row.use_in_voice && scope.row.use_in_sms"
                  >,
                </span>
                <span v-if="scope.row.use_in_sms">{{
                  __("Messaging Task")
                }}</span>
                <span
                  v-if="
                    (scope.row.use_in_sms && scope.row.use_in_workflow) ||
                      (scope.row.use_in_voice && scope.row.use_in_workflow)
                  "
                  >,
                </span>
                <span v-if="scope.row.use_in_workflow">{{
                  __("Workflow")
                }}</span>
                <span
                  v-if="
                    (scope.row.use_in_sms ||
                      scope.row.use_in_voice ||
                      scope.row.use_in_workflow) &&
                      scope.row.use_in_chatbot
                  "
                  >,
                </span>
                <span v-if="scope.row.use_in_chatbot">{{ __("Chatbot") }}</span>
              </template>
            </el-table-column>
            <el-table-column :label="__('Category')">
              <template slot-scope="scope">
                <span>{{ scope.row.category }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PaginationToolbar from "@/components/PaginationToolbar";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import PageHeader from "@/components/PageHeader";

export default {
  mixins: [BaseContent],
  components: {
    PaginationToolbar,
    PageHeader
  },
  data() {
    return {
      search: "",
      filters: ["node_type"],
      sortByColumns: ["node_type"],
      availableSortOptions: ["asc|desc"]
    };
  },

  computed: {
    ...mapState("nodeTypes", {
      nodeTypes: state => state.nodeTypes,
      isLoading: state => state.isLoading
    })
  },

  methods: {
    ...mapActions("nodeTypes", {
      contentAPI: "getNodeTypes",
      deleteContentMethod: "deleteNodeType"
    }),
    handleAltImg(event) {
      event.target.src = "/icons/variable.svg";
    },
    handleClear() {
      this.$refs.nodeTypeTable.setCurrentRow();
      this.handleClearSelection();
    },
    handleSingleClick(row, column, event) {
      event.stopPropagation();
      this.handleSelect(null, row);
    }
  },

  watch: {
    selectedItemIndex: {
      immediate: true,
      handler: function(val) {
        if (val !== -1 && val !== null) {
          this.$refs.nodeTypeTable.setCurrentRow(this.nodeTypes[val]);
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
$--color-node-hover-default: var(--theme-hover-color) !default;
$card-image-filter: invert(65%) sepia(43%) saturate(5014%) hue-rotate(170deg)
  brightness(104%) contrast(89%);
@import "~@/styles/content-buttons.scss";
@import "~@/styles/pagination-toolbar.scss";
@import "~@/styles/content-list.scss";
.rounded-menu-icon {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  object-fit: cover;
  background: white;
  margin-left: 10px;
}
</style>
