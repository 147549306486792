var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"100%"},on:{"click":_vm.handleClear}},[_c('page-header',{staticClass:"absolute",staticStyle:{"padding":"20px 30px","width":"calc(100% - 335px)"},attrs:{"title":_vm.__('Node Types')}}),_c('div',{staticStyle:{"padding":"0 36px","margin-top":"136px"}},[_c('el-form',{staticClass:"form-container"},[_c('PaginationToolbar',{attrs:{"sort-by-options":_vm.sortByOptions,"content-api":_vm.contentAPI,"show-slot":true,"default-sort-by":_vm.__('Name A-Z')},on:{"success":function($event){return _vm.$emit('replaceContent', $event)}}})],1),_c('el-scrollbar',{attrs:{"native":true}},[_c('div',{staticClass:"contentListContainer"},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}],ref:"nodeTypeTable",staticClass:"list-table",attrs:{"data":_vm.nodeTypes,"highlight-current-row":""},on:{"row-click":_vm.handleSingleClick}},[_c('el-table-column',{attrs:{"label":_vm.__('Node Type')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-row',{staticStyle:{"align-items":"center"},attrs:{"type":"flex","justify":"flex-start"}},[_c('img',{staticClass:"rounded-menu-icon",attrs:{"src":`/icons/${scope.row.node_type}.svg`,"onload":"SVGInject(this)"}}),_c('span',{staticStyle:{"margin-left":"20px"}},[_vm._v(_vm._s(scope.row.node_type))])])]}}])}),_c('el-table-column',{attrs:{"label":_vm.__('Use In')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.use_in_voice)?_c('span',[_vm._v(_vm._s(_vm.__("Voice Task")))]):_vm._e(),(scope.row.use_in_voice && scope.row.use_in_sms)?_c('span',[_vm._v(", ")]):_vm._e(),(scope.row.use_in_sms)?_c('span',[_vm._v(_vm._s(_vm.__("Messaging Task")))]):_vm._e(),(
                  (scope.row.use_in_sms && scope.row.use_in_workflow) ||
                    (scope.row.use_in_voice && scope.row.use_in_workflow)
                )?_c('span',[_vm._v(", ")]):_vm._e(),(scope.row.use_in_workflow)?_c('span',[_vm._v(_vm._s(_vm.__("Workflow")))]):_vm._e(),(
                  (scope.row.use_in_sms ||
                    scope.row.use_in_voice ||
                    scope.row.use_in_workflow) &&
                    scope.row.use_in_chatbot
                )?_c('span',[_vm._v(", ")]):_vm._e(),(scope.row.use_in_chatbot)?_c('span',[_vm._v(_vm._s(_vm.__("Chatbot")))]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":_vm.__('Category')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.category))])]}}])})],1)],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }